import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useAuthUser } from "@frontegg/nextjs";
import { getOperationRoute, routes } from "holocene-constants/routes";
import { useUserDetails } from "holocene-providers/common";
import { useGetCurrentUserInfo } from "holocene-hooks/users.hooks";
import { FeaturePermissionEnum } from "holocene-services/user.service/types";
import { useActiveOperation } from "holocene-hooks/utils.hooks";
import { ModuleEnum } from "holocene-components/settings/UserDetailsModal";

const Home: NextPage = () => {
  const user = useAuthUser();
  const { operationType } = useUserDetails();
  const { data: userInfo } = useGetCurrentUserInfo();
  const activeOperation = useActiveOperation();

  const pageToLoad = (() => {
    if (!userInfo) return "";

    const assignedPermissions =
      userInfo?.detailInfo.UserAssignedFeaturePermissions.filter(
        (userPermission) => userPermission.FeaturePermission.moduleName === activeOperation
      ) ?? [];
    const permissionMap = assignedPermissions.reduce(
      (obj: Record<string, boolean>, permissionVal) => {
        return {
          ...obj,
          [permissionVal.FeaturePermission.feature]: true,
        };
      },
      {}
    );

    if (permissionMap[FeaturePermissionEnum.CONTROL_TOWER]) {
      return getOperationRoute("controlTower", activeOperation);
    }
    if (permissionMap[FeaturePermissionEnum.PERFORMANCE]) {
      return getOperationRoute("performanceDashboard", activeOperation);
    }
    if (activeOperation === ModuleEnum.purchase) {
      if (permissionMap[FeaturePermissionEnum.PURCHASE_ORDERS]) {
        return "/purchase/purchase-orders";
      }
      if (permissionMap[FeaturePermissionEnum.DELIVERY]) {
        return "/purchase/inbound-deliveries";
      }
    } else {
      if (permissionMap[FeaturePermissionEnum.DELIVERY]) {
        return getOperationRoute("deliveryQueue", activeOperation);
      }
      if (permissionMap[FeaturePermissionEnum.DISPATCH]) {
        return getOperationRoute("deliveryDispatched", activeOperation);
      }
    }

    if (permissionMap[FeaturePermissionEnum.TRADE_LANES]) {
      return getOperationRoute("tradeLaneV3", activeOperation);
    }
    if (permissionMap[FeaturePermissionEnum.SETTINGS]) {
      return getOperationRoute("settings", activeOperation);
    }
    return routes.settings;
  })();

  const router = useRouter();

  if (user?.accessToken) {
    if (typeof window !== "undefined") {
      localStorage.setItem("userInfo", JSON.stringify({ accessToken: user?.accessToken }));
    }
    if (pageToLoad) {
      router.push(pageToLoad);
    }
  }
  return null;
};

export default Home;
